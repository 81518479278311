import { Suspense, ReactNode } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  VStack,
  useAccordionItemState,
} from '@chakra-ui/react';

import { useFeatureFlagsContext } from '@services/feature_flag_provider';
import { useDevModeContext } from '@services/dev_mode_provider';
import { trpc } from '@services/trpc';
import { ProposeNewUser } from '@pages/Connections/ProposeNewUser';
import { Icon } from '@utils/icons';

import { OurNavLink } from '../NavLink';

import { ActivityNavLink } from './Activity';
import { PortfolioNavLink } from './Portfolio';
import { CountBox } from './CountBox';

const PipelineElements = ({ showIcons }: { showIcons?: boolean }) => {
  const { data } = trpc.views.pipeline.getDealsCounts.useQuery();

  return (
    <CollapsableArea
      title={<Text>Pipeline</Text>}
      links={
        <>
          <OurNavLink
            to="/pipeline/new"
            py="6px"
            title="New"
            icon={<Icon name="inbox" size="20px" />}
            showIcons={showIcons}
          >
            {data ? <CountBox count={data.counts.new} /> : null}
          </OurNavLink>
          <OurNavLink
            to="/pipeline/active"
            py="6px"
            title="Active"
            icon={<Icon name="schedule" size="20px" />}
            showIcons={showIcons}
          >
            {data ? <CountBox count={data.counts.active} /> : null}
          </OurNavLink>
          <OurNavLink
            to="/pipeline/all"
            py="6px"
            title="All Deals"
            icon={<Icon name="category" size="20px" />}
            showIcons={showIcons}
          />
        </>
      }
    />
  );
};

const PortfolioElements = ({ showIcons }: { showIcons?: boolean }) => {
  const { isEnabled } = useFeatureFlagsContext();
  const devMode = useDevModeContext();

  return isEnabled('DIRECTORY') ? (
    <CollapsableArea
      title={<Text>Portfolio</Text>}
      links={
        <>
          {isEnabled('DIRECTORY') ? (
            <OurNavLink
              to="/portfolio"
              py="6px"
              title="Companies"
              icon={<Icon name="chartData" size="20px" />}
              showIcons={showIcons}
            />
          ) : null}
          {isEnabled('DIRECTORY') ? (
            <OurNavLink
              to="/portfolio/investor-updates"
              py="6px"
              title="Investor Updates"
              icon={<Icon name="update" size="20px" />}
              showIcons={showIcons}
            />
          ) : null}
          {isEnabled('PORTFOLIO') ? <PortfolioNavLink showIcons={showIcons} /> : null}
          {isEnabled('DIRECTORY') ? (
            <OurNavLink
              to="/investments"
              py="6px"
              title="Investments"
              icon={<Icon name="chartData" size="20px" />}
              showIcons={showIcons}
            />
          ) : null}
        </>
      }
    />
  ) : null;
};

const NetworkElements = ({ showIcons }: { showIcons?: boolean }) => {
  const utils = trpc.useUtils();
  const devMode = useDevModeContext();

  return (
    <CollapsableArea
      title={<Text>Network</Text>}
      links={
        <>
          <OurNavLink
            py="6px"
            to="/connections"
            onMouseOver={() => {
              const popularInvestors = import.meta.env.VITE_POPULAR_INVESTORS_LIST_ID;
              if (popularInvestors) {
                utils.views.profile.allUserProfiles.fetch({ listId: popularInvestors, hasCompletedRegistration: true });
              }

              utils.views.connections.getConnectionsList.fetch({ type: 'SHARING_WITH_THEM' });
              utils.views.connections.getConnectionsList.fetch({ type: 'SHARING_WITH_ME' });
            }}
            title="Connections"
            icon={<Icon name="group" size="20px" />}
            showIcons={showIcons}
          />
          <OurNavLink
            py="6px"
            to="/explore"
            title="Explore"
            icon={<Icon name="explore" size="20px" />}
            showIcons={showIcons}
          />
          {devMode ? (
            <OurNavLink
              py="6px"
              to="/radar"
              title="Radar"
              icon={<Icon name="rocketLaunch" size="20px" />}
              showIcons={showIcons}
            />
          ) : null}
          {devMode ? (
            <OurNavLink
              py="6px"
              to="/co-investors"
              title="Co-Investors"
              icon={<Icon name="hub" size="20px" />}
              showIcons={showIcons}
            />
          ) : null}
        </>
      }
    />
  );
};

const AccordionIcon = () => {
  const { isOpen } = useAccordionItemState();

  return (
    <Box
      ml="8px"
      transform={isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
      transition="transform 0.2s ease-in-out"
      pointerEvents="none"
    >
      <Icon name="arrowDropDown" />
    </Box>
  );
};

const CollapsableArea = ({ title, links }: { title: ReactNode; links: ReactNode }) => {
  return (
    <AccordionItem w="100%">
      <VStack align="stretch" w="100%" gap="0">
        <AccordionButton justifyContent="flex-start" _hover={{ color: '#666666' }} pl="8px">
          {title}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pt="0" pr="0" pl="0">
          {links}
        </AccordionPanel>
      </VStack>
    </AccordionItem>
  );
};

const DevNavElementsRequiringDataFetches = ({ showIcons }: { showIcons?: boolean }) => {
  const { isEnabled } = useFeatureFlagsContext();
  const devMode = useDevModeContext();
  return (
    <>
      <PortfolioElements showIcons={showIcons} />
      <NetworkElements showIcons={showIcons} />
      <ActivityNavLink showIcons={showIcons} />
      {isEnabled('CHAT') ? (
        <OurNavLink
          py="6px"
          to="/messages"
          title="Messages"
          icon={<Icon name="chat" size="20px" />}
          showIcons={showIcons}
        />
      ) : null}
      {devMode ? (
        <OurNavLink
          to="/profiles-manager"
          title="Profiles"
          icon={<Icon name="badge" size="20px" />}
          showIcons={showIcons}
        />
      ) : null}
    </>
  );
};

const NavElements = ({ showIcons }: { showIcons?: boolean }) => {
  return (
    <Accordion defaultIndex={[0, 1, 2, 3, 4, 5]} allowMultiple w="100%">
      <PipelineElements showIcons={showIcons} />
      <Suspense
        fallback={
          <OurNavLink
            py="6px"
            to="/activity"
            title="Activity"
            icon={<Icon name="notifications" size="20px" />}
            showIcons={showIcons}
          />
        }
      >
        <DevNavElementsRequiringDataFetches showIcons={showIcons} />
      </Suspense>
      <ProposeNewUser mx="0" pl="8px" />
    </Accordion>
  );
};

export { NavElements };
