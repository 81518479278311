import { useMemo } from 'react';

import { getLastViewedPortfolio } from '@services/localStorage/lastViewedPortfolio';
import { useUserContext } from '@services/user_provider';
import { Icon } from '@utils/icons';

import { OurNavLink } from '../NavLink';

const PortfolioNavLink = ({ showIcons }: { showIcons?: boolean }) => {
  const { email, selectedTeam } = useUserContext();

  const lastViewedPortfolio = useMemo(() => {
    if (email && selectedTeam?.id) {
      return getLastViewedPortfolio({ email, teamId: selectedTeam?.id });
    }
  }, [email, selectedTeam?.id]);

  const url = useMemo(() => {
    return lastViewedPortfolio ? `/valuations/${lastViewedPortfolio}` : '/valuations';
  }, []);

  return (
    <OurNavLink py="6px" to={url} title="Valuations" icon={<Icon name="paid" size="20px" />} showIcons={showIcons} />
  );
};

export { PortfolioNavLink };
