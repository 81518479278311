import { LinkBox, LinkOverlay, LinkBoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

import { Responsive } from '@components/Responsive';

import { useNavContext } from './Sidebar';

const MOBILE_BREAKPOINT = '768px';

const OurNavLink = ({
  children,
  title,
  icon,
  to,
  onMouseOver,
  showIcons,
  ...props
}: {
  children?: ReactNode;
  to: string;
  onMouseOver?: () => void;
  title: string;
  icon?: ReactNode;
  showIcons?: boolean;
} & LinkBoxProps) => {
  const match = useMatch(to);
  const { mobileOnClose } = useNavContext();
  // using Show and Hide here is a horrible hack, but I couldn't think of anything elegant quickly
  // it's a hack because I'm using the viewport width, rather than a prop,
  // to solve my probem of changiung the behaviour of link elements between the desktop navmenu, and mobile navmenu drawer
  return (
    <Responsive
      breakpoint={MOBILE_BREAKPOINT}
      above={
        <LinkBox
          position="relative"
          w="100%"
          px="8px"
          display="flex"
          flexDir="row"
          alignItems="center"
          gap="12px"
          py="6px"
          borderRadius="full"
          color="grey.text_dark"
          {...(match
            ? {
                color: 'purple.primary',
                bg: 'purple.veryLight',
              }
            : null)}
          _hover={{
            color: 'purple.primary',
            bg: 'rgba(135, 120, 247, 0.05)',
          }}
          {...props}
        >
          {showIcons ? icon : null}
          <LinkOverlay
            as={NavLink}
            to={to}
            color="grey.text_dark"
            {...(match
              ? {
                  color: 'purple.primary',
                  fontWeight: '500',
                }
              : null)}
            _hover={{
              color: 'purple.primary',
              fontWeight: '500',
            }}
            w="100%"
            whiteSpace="nowrap"
            onMouseOver={onMouseOver}
          >
            {title}
          </LinkOverlay>
          {children}
        </LinkBox>
      }
      below={
        <LinkBox
          position="relative"
          w="100%"
          px="8px"
          display="flex"
          flexDir="row"
          alignItems="center"
          gap="12px"
          py="6px"
          borderRadius="full"
          color="grey.text_dark"
          {...(match
            ? {
                color: 'purple.primary',
                bg: 'purple.veryLight',
              }
            : null)}
          _hover={{
            color: 'purple.primary',
            bg: 'rgba(135, 120, 247, 0.05)',
          }}
          {...props}
        >
          {showIcons ? icon : null}
          <LinkOverlay
            as={NavLink}
            to={to}
            color="grey.text_dark"
            {...(match
              ? {
                  color: 'purple.primary',
                  fontWeight: '500',
                }
              : null)}
            _hover={{
              color: 'purple.primary',
              fontWeight: '500',
            }}
            w="100%"
            whiteSpace="nowrap"
            onMouseOver={onMouseOver}
            onMouseDown={() => mobileOnClose()}
          >
            {title}
          </LinkOverlay>
          {children}
        </LinkBox>
      }
    />
  );
};

export { OurNavLink };
