import { Menu, MenuButton, Button, Portal, MenuList, MenuItem, Text } from '@chakra-ui/react';

import { DealStatus } from '@generated/graphql';
import { dealStatusOptions } from '@pages/Deals/StatusDropdown';
import { prettifyValue } from '@pages/Deals/StatusFilter';
import { trpc } from '@services/trpc';

export function StatusDropdown({ status, dealId }: { status: DealStatus; dealId: string }) {
  const { mutateAsync: updateStatus } = trpc.views.deal.batchUpdateStatus.useMutation();
  const utils = trpc.useUtils();
  const selectedOption = dealStatusOptions[status];
  return (
    <Menu>
      <MenuButton as={Button} pointerEvents={'none'} variant="ghost" p="12px" h="24px" borderRadius="8px">
        <Text
          fontFamily="DM Mono"
          fontSize={'14px'}
          fontWeight={500}
          fontStyle="12px"
          lineHeight={1.2}
          color={selectedOption.colour}
        >
          {status !== 'NO_STATUS' ? prettifyValue(selectedOption.value) : '+ Status'}
        </Text>
      </MenuButton>
      <Portal>
        <MenuList
          zIndex={1000}
          background={'white'}
          border="1px solid"
          borderColor="grey.border"
          borderRadius={4}
          gap={2}
        >
          {Object.values(dealStatusOptions)
            .filter((o) => o.value !== DealStatus.NO_STATUS)
            .map((status) => (
              <MenuItem
                key={status.value}
                p="8px"
                borderRadius="4px"
                onClick={async () => {
                  await updateStatus(
                    {
                      ids: [dealId],
                      status: status.value,
                    },
                    {
                      onSuccess: () => {
                        utils.views.companies.getCompanies.invalidate();
                      },
                    },
                  );
                }}
              >
                <Text
                  fontFamily="DM Mono"
                  fontSize={'14px'}
                  fontWeight={500}
                  fontStyle="12px"
                  lineHeight={1.2}
                  color={status.colour}
                >
                  {prettifyValue(status.value)}
                </Text>
              </MenuItem>
            ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}
