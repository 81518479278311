import { useCallback, useState } from 'react';

import { DealStatus } from '@generated/graphql';
import { TextSelect } from '@components/v2/Select';
import convertUnderscore from '@utils/convertUnderscore';
import { Icon } from '@utils/icons';
import { Color } from '@styled/themes/v2';

const dealStatusOptions = {
  [DealStatus.NO_STATUS]: { value: DealStatus.NO_STATUS, colour: '#616161' },
  [DealStatus.CONSIDERING]: { value: DealStatus.CONSIDERING, colour: '#D8601D' },
  [DealStatus.COMMITTED]: { value: DealStatus.COMMITTED, colour: '#089741' },
  [DealStatus.INVESTED]: { value: DealStatus.INVESTED, colour: '#04a3d8' },
  [DealStatus.PASSED]: { value: DealStatus.PASSED, colour: '#D81D1D' },
  [DealStatus.LOST]: { value: DealStatus.LOST, colour: '#A76F6F' },
  [DealStatus.WATCHING]: { value: DealStatus.WATCHING, colour: '#7f74ce' },
  [DealStatus.HELPING_FUNDRAISE]: { value: DealStatus.HELPING_FUNDRAISE, colour: '#BD00E3' },
} as const;

const statusOptions = Object.values(dealStatusOptions).map(({ value }) => value);

interface Props {
  selected: DealStatus[];
  onChange: (newTab: DealStatus[]) => void;
}

export const prettifyValue = (value: DealStatus) =>
  value === 'HELPING_FUNDRAISE' ? 'Supporting' : convertUnderscore(value);
const barChartIcon = <Icon name="barChart" />;

function StatusFilter({ selected, onChange }: Props) {
  const [innerValue, setInnerValue] = useState('');
  const controlWrapperProps = useCallback(
    ({ hasValue }: { hasValue: boolean }) => ({
      fontFamily: 'DM Mono',
      spacing: '0px',
      minHeight: '32px',
      minWidth: '100px',
      pr: '0px',
      borderColor: hasValue ? '#8778F766' : 'grey.divider',
    }),
    [],
  );

  return (
    <TextSelect<DealStatus, true>
      hideSelectedOptions={false}
      placeholder="Status"
      options={statusOptions}
      values={selected}
      onChange={onChange}
      isMulti={true}
      placeholderShownWithInput={false}
      icon={barChartIcon}
      isLoading={false}
      prettifyValue={prettifyValue}
      externalInput={innerValue}
      setExternalInput={setInnerValue}
      styleOverrides={{
        menu: {
          zIndex: 2,
          right: '0',
          width: '120px',
        },
        selectContainer: {
          color: 'grey.primary',
        },
        input: {
          fontFamily: 'DM Mono',
          maxWidth: '100%',
          padding: '0',
          ':not(:focus-within)': {
            //width: '0px',
          },
        },
        control: {
          padding: '2px 6px !important',
          cursor: 'pointer',
          borderRadius: '4px',
          wrapper: {
            borderColor: 'blue',
          },
        },
        dropdownIndicator: {
          display: 'none',
        },
        option: (props) => {
          // TODO: get rid of this any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const pps = props as any;

          return {
            fontFamily: 'DM Mono',
            color: dealStatusOptions[pps.data.value as DealStatus].colour,
            backgroundColor: pps.isSelected ? Color.GREY_DIVIDER : pps.isFocused ? Color.GREY_BACKGROUND : 'white',
          };
        },
        multiValueLabel: (props) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const pps = props as any;
          return {
            fontFamily: 'DM Mono',
            color: dealStatusOptions[pps.data.value as DealStatus].colour,
          };
        },
        valueContainer: {
          gap: '4px',
          color: '#00000055',
        },
      }}
      activeColor="grey.text_dark"
      boxProps={{ maxW: '100%' }}
      controlWrapperProps={controlWrapperProps}
    />
  );
}

export { StatusFilter };
