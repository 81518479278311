import {
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  LinkBox,
  Link,
  Text,
  useDisclosure,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  LinkOverlay,
  Button,
  Skeleton,
  SkeletonCircle,
  Fade,
  Kbd,
  Tag,
} from '@chakra-ui/react';
import { useMemo, useState, useEffect, useRef } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { ModalBody } from '@chakra-ui/react';

import { useDebounce } from '@hooks/useDebounce';
import { RouterOutputs, trpc } from '@services/trpc';
import { getHostname, toUrl } from '@utils/url_manipulations';
import { CompanyLogo } from '@components/CompanyLogo';
import { BorderRadius } from '@styled/themes/v2';
import { Icon } from '@utils/icons';
import { DealStatus } from '@generated/graphql';
import { notNull } from '@utils/types';
import ProfileNameWithPreview from '@pages/Activity/ProfileNameWithPreview';
import { Avatar } from '@components/Avatar';
import { getNameInitials } from '@utils/nameInitials';

import { StatusDropdown } from './StatusDropdown';

function HighlightedText({
  text,
  fontSize = '14px',
  color = 'black',
  display = 'block',
  gap = '4px',
}: {
  text: string;
  fontSize?: string;
  color?: string;
  display?: string;
  gap?: string;
}) {
  const html = text.replace(/<b>(.*?)<\/b>/g, '<span style="color: black; font-weight: bold;">$1</span>');
  return (
    <Text
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize={fontSize}
      color={color}
      display={display}
      gap={gap}
      dangerouslySetInnerHTML={{ __html: html }}
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflow: 'hidden',
      }}
    />
  );
}

export function SearchModal() {
  const [input, setInput] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState<'DEAL' | 'PORTCO' | 'INVESTOR'>('DEAL');
  const debouncedInput = useDebounce(input, 200);
  const { data, isLoading, isFetching } = trpc.views.search.search.useQuery(
    { search: debouncedInput },
    { enabled: debouncedInput.length > 0, keepPreviousData: true },
  );

  const initialFocusRef = useRef(null);

  const results = useMemo(() => {
    if (!debouncedInput) {
      return [];
    }
    return data?.filter((t) => t.type === filter) ?? [];
  }, [data, filter, debouncedInput]);

  useEffect(() => {
    if (!isOpen) {
      setInput('');
      setFilter('DEAL');
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        onOpen();
      }

      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onOpen, onClose, isOpen]);

  const showLoading = debouncedInput.length > 0 && (isLoading || isFetching);
  const showEmptyState = debouncedInput.length > 0 && !showLoading && (!results || results.length === 0);

  const portcoCount = useMemo(() => {
    return data?.filter((t) => t.type === 'PORTCO').length || 0;
  }, [data]);
  const dealCount = useMemo(() => {
    return data?.filter((t) => t.type === 'DEAL').length || 0;
  }, [data]);
  const investorCount = useMemo(() => {
    return data?.filter((t) => t.type === 'INVESTOR').length || 0;
  }, [data]);

  useEffect(() => {
    if (portcoCount > 0) {
      setFilter('PORTCO');
    } else if (dealCount > 0) {
      setFilter('DEAL');
    } else if (investorCount > 0) {
      setFilter('INVESTOR');
    }
  }, [dealCount, portcoCount, investorCount]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent maxW={'740px'} w="100%" maxH={'80vh'} p="0px" boxShadow="lg">
          <ModalHeader p="16px">
            <InputGroup
              alignSelf={'center'}
              alignContent={'center'}
              w="100%"
              gap={'0px'}
              borderRadius={'6px'}
              maxW={'740px'}
              bg="white"
            >
              <InputLeftElement position={'relative'} p="0px" pl="12px">
                <Icon name="search" size={'20px'} color="gray.500" />
              </InputLeftElement>
              <Input
                ref={initialFocusRef}
                fontSize={'16px'}
                fontWeight={'400'}
                h="42px"
                tabIndex={1}
                variant={'plain'}
                value={input}
                border={'none'}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Search..."
                w="100%"
                spellCheck={false}
                _focus={{
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
            {debouncedInput && !showLoading && data && data.length > 0 && (
              <HStack px="12px" pt="12px" spacing="8px" overflowX="auto" pb="4px" w="100%">
                {dealCount > 0 && (
                  <FilterButton
                    label={`Pipeline (${dealCount})`}
                    isActive={filter === 'DEAL'}
                    onClick={() => setFilter('DEAL')}
                  />
                )}
                {portcoCount > 0 && (
                  <FilterButton
                    label={`Portfolio (${portcoCount})`}
                    isActive={filter === 'PORTCO'}
                    onClick={() => setFilter('PORTCO')}
                  />
                )}
                {investorCount > 0 && (
                  <FilterButton
                    label={`Investors (${investorCount})`}
                    isActive={filter === 'INVESTOR'}
                    onClick={() => setFilter('INVESTOR')}
                  />
                )}
              </HStack>
            )}
          </ModalHeader>
          <ModalBody w="100%" overflow={'auto'} py="0px" maxH="calc(80vh - 100px)">
            <Fade in={true}>
              {showLoading ? (
                <SearchResultsSkeleton count={3} />
              ) : showEmptyState ? (
                <EmptyState />
              ) : (
                <SearchResults results={results} onClick={onClose} />
              )}
            </Fade>
          </ModalBody>
        </ModalContent>
      </Modal>
      <HStack
        p="12px"
        py="4px"
        border={'1px solid #E4E4E7'}
        borderRadius="full"
        onClick={onOpen}
        cursor="pointer"
        color="gray.700"
        w="100%"
        transition="all 0.2s"
        _hover={{
          bg: 'rgba(135, 120, 247, 0.05)',
          borderColor: '#8778F7',
        }}
        justify={'space-between'}
      >
        <HStack>
          <Icon name="search" size={'20px'} />
          <Text fontSize="14px" fontWeight="400">
            Search...
          </Text>
        </HStack>

        <Kbd color="black" fontSize={'12px'} px="8px" py="4px" fontWeight={'500'} bg={'white'}>
          ⌘ K
        </Kbd>
      </HStack>
    </>
  );
}

const FilterButton = ({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) => (
  <Button
    onClick={onClick}
    borderRadius="full"
    border="1px solid"
    px="16px"
    py="6px"
    height="32px"
    flexShrink={0}
    borderColor={isActive ? 'purple.primary' : 'gray.200'}
    color={isActive ? 'purple.primary' : 'gray.700'}
    bg={isActive ? 'rgba(135, 120, 247, 0.05)' : 'white'}
    _hover={{
      bg: isActive ? 'rgba(135, 120, 247, 0.1)' : 'gray.50',
    }}
    fontSize="14px"
    fontWeight={isActive ? '600' : '500'}
  >
    {label}
  </Button>
);

const EmptyState = () => (
  <VStack pb="16px" spacing="16px" align="center">
    <Text fontSize="16px" fontWeight="600" color="gray.700">
      No results found.
    </Text>
  </VStack>
);

const SearchResultsSkeleton = ({ count = 3 }: { count?: number }) => (
  <VStack alignItems="flex-start" w="100%" p="0px" pb="12px" spacing="16px">
    {Array.from({ length: count }).map((x, i) => (
      <VStack
        key={i}
        userSelect="none"
        align="stretch"
        p="12px"
        borderRadius="6px"
        bg="white"
        border="1px solid #E4E4E7"
        w="100%"
      >
        <HStack spacing="12px">
          <SkeletonCircle size="40px" />
          <VStack align="stretch" spacing="8px" w="100%">
            <Skeleton height="20px" width="180px" />
            <HStack>
              <Skeleton height="16px" width="120px" />
              <Skeleton height="16px" width="80px" />
            </HStack>
          </VStack>
          <Skeleton height="28px" width="100px" />
        </HStack>
        <Skeleton height="36px" width="100%" mt="4px" />
      </VStack>
    ))}
  </VStack>
);

function SearchResults({
  results,
  onClick,
}: {
  results: RouterOutputs['views']['search']['search'] | null | undefined;
  onClick?: () => void;
}) {
  if (!results || results.length === 0) {
    return null;
  }

  return (
    <VStack alignItems="flex-start" w="100%" overflow="hidden">
      <VStack overflow="hidden" align="stretch" w="100%" spacing="16px" p="0px" pb="16px" onClick={onClick}>
        {results.map((result) => (
          <Flex key={result.id} w="100%">
            {result.type === 'PORTCO' ? (
              <CompanyCard company={result} />
            ) : result.type === 'DEAL' ? (
              <DealCard company={result} />
            ) : (
              <InvestorCard investor={result} />
            )}
          </Flex>
        ))}
      </VStack>
    </VStack>
  );
}

const CompanyCard = ({ company }: { company: RouterOutputs['views']['search']['search'][number] }) => {
  if (company.type !== 'PORTCO') {
    return null;
  }
  const { funds } = company.data as { funds: string[] };

  return (
    <LinkBox role="group" _hover={{ zIndex: 1 }} w="100%">
      <VStack
        userSelect="none"
        alignSelf="center"
        align="stretch"
        justify={'flex-start'}
        position="relative"
        gap="8px"
        cursor={'pointer'}
        borderRadius="8px"
        bg="white"
        border="1px solid #E4E4E7"
        p="16px"
        pt="10px"
        transition="all 0.2s"
        _hover={{
          borderColor: '#8778F7',
        }}
        w="100%"
      >
        <LinkOverlay as={RRLink} to={`/c/${company.data?.slug}`} _activeLink={{ color: 'black' }} whiteSpace="nowrap" />
        <HStack px="0px" justifyContent="space-between" w="100%" alignItems="flex-start" spacing="12px">
          <HStack alignItems="flex-start" spacing="12px" w="100%">
            <CompanyLogo
              alt={company.name ?? ''}
              url={company.website}
              size={48}
              fetchSize={128}
              borderRadius={BorderRadius.REGULAR}
              border
              useUnicornFallback={false}
            />
            <VStack w="100%" align="stretch" spacing="8px">
              <VStack align="stretch" spacing="2px">
                <Text as="h2" color="black" fontSize="16px" lineHeight="1.4" fontWeight={600} noOfLines={1}>
                  {company.name || 'Unknown'}
                </Text>

                <HStack spacing="8px">
                  {company.website ? (
                    <Link
                      as={RRLink}
                      fontSize="14px"
                      color="gray.600"
                      key="url"
                      to={toUrl(company.website)?.toString() ?? ''}
                      textDecoration="none"
                      alignItems="center"
                      target="_blank"
                      rel="noreferrer nofollow"
                      maxW="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      _hover={{ color: 'purple.primary', textDecoration: 'underline' }}
                    >
                      {company.website ? getHostname(company.website) : ''}
                    </Link>
                  ) : null}
                  {company.data.country ? (
                    <HStack spacing="4px">
                      <Icon name="locationOn" size="14px" color="gray.500" />
                      <HighlightedText
                        text={company.data.country}
                        fontSize="14px"
                        color="gray.600"
                        display="inline-flex"
                      />
                    </HStack>
                  ) : null}
                </HStack>
              </VStack>
            </VStack>
          </HStack>
          {funds.length > 0 ? (
            <Text
              fontSize="14px"
              py="2px"
              px="8px"
              color="#64758B"
              flexShrink={{ base: 1, md: 0 }}
              fontWeight="semibold"
              overflow="hidden" // hide overflowing text
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {funds.filter(notNull).join(' + ')}
            </Text>
          ) : null}
        </HStack>
        <HighlightedText text={company.data?.snippet ?? ''} color="gray.600" />
      </VStack>
    </LinkBox>
  );
};

const InvestorCard = ({ investor }: { investor: RouterOutputs['views']['search']['search'][number] }) => {
  if (investor.type !== 'INVESTOR') {
    return null;
  }
  const initials = getNameInitials({ name: investor.name });

  return (
    <LinkBox role="group" _hover={{ zIndex: 1 }} w="100%">
      <VStack
        userSelect="none"
        alignSelf="center"
        align="stretch"
        justify={'flex-start'}
        position="relative"
        gap="8px"
        cursor={'pointer'}
        borderRadius="8px"
        bg="white"
        border="1px solid #E4E4E7"
        p="16px"
        pt="10px"
        transition="all 0.2s"
        _hover={{
          borderColor: '#8778F7',
        }}
        w="100%"
      >
        <LinkOverlay as={RRLink} to={`/${investor.data?.slug}`} _activeLink={{ color: 'black' }} whiteSpace="nowrap" />
        <HStack px="0px" justifyContent="space-between" w="100%" alignItems="flex-start">
          <HStack spacing="12px" flexGrow="1">
            <Avatar initials={initials ?? ''} src={investor.data.imageUrl ?? undefined} size={48} borderRadius={48} />
            <VStack spacing="4px" align="stretch">
              <HStack>
                <ProfileNameWithPreview
                  fullname={investor.name ?? ''}
                  slug={investor.data.slug ?? ''}
                  linkProps={{
                    fontSize: '16px',
                    fontWeight: '500',
                    pb: investor.data.workplaces?.length ? '0' : '6px',
                  }}
                  disablePopover={true}
                />
              </HStack>
              {investor.data.workplaces?.length ? (
                <Text color="grey.text_medium">{investor.data.workplaces[0]}</Text>
              ) : null}
            </VStack>
          </HStack>
          {investor.data.sharingWithThem && investor.data.sharingWithMe ? (
            <HStack spacing="8px">
              <Tag fontFamily="DM Mono">Connected</Tag>
            </HStack>
          ) : null}
        </HStack>
      </VStack>
    </LinkBox>
  );
};

const DealCard = ({ company }: { company: RouterOutputs['views']['search']['search'][number] }) => {
  return (
    <LinkBox role="group" _hover={{ zIndex: 1 }} w="100%">
      <VStack
        userSelect="none"
        alignSelf="center"
        align="stretch"
        justify={'flex-start'}
        position="relative"
        gap="8px"
        cursor={'pointer'}
        borderRadius="8px"
        bg="white"
        border="1px solid #E4E4E7"
        p="16px"
        pt="10px"
        transition="all 0.2s"
        _hover={{
          borderColor: '#8778F7',
        }}
      >
        <LinkOverlay
          as={RRLink}
          to={`/d/${company.data?.dealSlug}`}
          _activeLink={{ color: 'black' }}
          whiteSpace="nowrap"
        />
        <HStack px="0px" justifyContent="space-between" w="100%" alignItems="flex-start">
          <HStack alignItems="flex-start" spacing="12px">
            <CompanyLogo
              alt={company.name ?? ''}
              url={company.website}
              size={48}
              fetchSize={128}
              borderRadius={BorderRadius.REGULAR}
              border
              useUnicornFallback={false}
            />
            <VStack w="100%" align="stretch" spacing="8px">
              <VStack align="stretch" spacing="2px">
                <Text as="h2" color="black" fontSize="16px" lineHeight="1.4" fontWeight={600} noOfLines={1}>
                  {company.name || 'Unknown'}
                </Text>

                <HStack spacing="8px">
                  {company.website ? (
                    <Link
                      as={RRLink}
                      fontSize="14px"
                      color="gray.600"
                      key="url"
                      to={toUrl(company.website)?.toString() ?? ''}
                      textDecoration="none"
                      alignItems="center"
                      target="_blank"
                      rel="noreferrer nofollow"
                      maxW="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      _hover={{ color: 'purple.primary', textDecoration: 'underline' }}
                    >
                      {company.website ? getHostname(company.website) : ''}
                    </Link>
                  ) : null}
                  {company.data.country ? (
                    <HStack spacing="4px">
                      <Icon name="locationOn" size="14px" color="gray.500" />
                      <HighlightedText
                        text={company.data.country}
                        fontSize="14px"
                        color="gray.600"
                        display="inline-flex"
                      />
                    </HStack>
                  ) : null}
                </HStack>
              </VStack>
            </VStack>
          </HStack>
          {company.data.status === 'NO_STATUS' ? (
            <Text
              fontFamily="DM Mono"
              fontSize={'14px'}
              fontWeight={500}
              fontStyle="12px"
              lineHeight={1.2}
              p="12px"
              h="24px"
              borderRadius="8px"
            >
              {'No Status'}
            </Text>
          ) : (
            <StatusDropdown status={company.data.status as DealStatus} dealId={''} />
          )}
        </HStack>
        <HighlightedText text={company.data?.snippet ?? ''} color="gray.600" />
      </VStack>
    </LinkBox>
  );
};
