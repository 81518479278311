export function notUndefined<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}

export function notNull<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function neverAsAny(value: never): any {
  return value;
}

// typescript helpers for typing nested paths
// e.g. type Foo = { bar: { baz: string } }
// type AllPaths = DotKey<Foo> // 'bar' | 'bar.baz'
// type NestedPath = DotKey<Foo, ['bar', 'baz']> // 'bar.baz'
// type NestedValue = DotValue<Foo, ['bar', 'baz']> // string
// type NestedValueFromKey = ValueFromKey<Foo, 'bar.baz'> // string

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRecord = Record<any, unknown>;

type Values<T> = T extends AnyRecord ? T[keyof T] : never;

type AccessPath<T> = Values<{
  [K in keyof T & (string | number)]: T[K] extends infer U | null | undefined
    ? U extends AnyRecord | unknown[]
      ? [K] | [K, ...AccessPath<U>]
      : [K]
    : [K];
}>;

type DotKey<T extends AnyRecord | unknown[], Path extends (string | number)[] = AccessPath<T>> = Path extends [
  infer U extends string | number,
]
  ? U
  : Path extends [infer U extends string | number, ...infer V extends (string | number)[]]
    ? T[U] extends infer Z | null | undefined
      ? Z extends AnyRecord | unknown[]
        ? `${U}.${DotKey<Z, V>}`
        : U
      : U
    : never;

type DotValue<T extends AnyRecord | unknown[], Path extends (string | number)[] = AccessPath<T>> = Path extends [
  infer U extends string | number,
]
  ? T[U]
  : Path extends [infer U extends string | number, ...infer V extends (string | number)[]]
    ? T[U] extends infer Z | null | undefined
      ? Z extends AnyRecord | unknown[]
        ? DotValue<Z, V>
        : never
      : never
    : never;

type AccessPathFromDotKey<T extends string> = T extends `${infer U}.${infer V}` ? [U, ...AccessPathFromDotKey<V>] : [T];

type ValueFromKey<T extends AnyRecord | unknown[], U extends string> = DotValue<T, AccessPathFromDotKey<U>>;

export type { DotKey, ValueFromKey };
