import React, {
  ComponentType,
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSSProperties } from 'styled-components';
import { HStack, Text, Box } from '@chakra-ui/react';
import ReactSelect, {
  components,
  ContainerProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueProps,
  PlaceholderProps,
  MultiValue,
  SingleValue,
  CSSObjectWithLabel,
  InputProps,
  OptionProps,
  ClearIndicatorProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import ExpandMoreIcon from '@/assets/icons/expand_more.svg?react';
import { Color } from '@styled/themes/v2';
import { Icon } from '@utils/icons';

const control = function <T>(
  icon: ReactNode,
  controlWrapperProps?: ComponentProps<typeof HStack> | ((props: ControlProps<T>) => ComponentProps<typeof HStack>),
) {
  return (props: ControlProps<T>) => {
    return (
      <HStack
        width="100%"
        border="1px solid"
        borderColor="grey.divider"
        borderRadius="6px"
        alignItems="center"
        paddingX="10px"
        paddingY="3px"
        fontSize="12px"
        backgroundColor="white"
        overflow="auto"
        _focusWithin={{
          border: '1px solid',
          borderColor: 'purple.primary',
          boxShadow: '0px 0px 0px 3px #8778F71A',
          boxShadowColor: 'purple.primary',
          outline: 'none',
        }}
        {...(typeof controlWrapperProps === 'function' ? controlWrapperProps(props) : controlWrapperProps)}
      >
        {icon}
        <components.Control {...props} />
      </HStack>
    );
  };
};

const ClearIndicator = function <T, IsMulti extends boolean>(props: ClearIndicatorProps<T, IsMulti>) {
  const {
    getStyles,
    clearValue,
    isMulti,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      onMouseDown={
        isMulti
          ? restInnerProps.onMouseDown
          : (e) => {
              e.preventDefault();
              clearValue();
            }
      }
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      <Icon size="12px" name="close" />
    </div>
  );
};

const Option = function <T>({ children, ...props }: OptionProps<T>) {
  return <components.Option {...props}>{children}</components.Option>;
};

const SelectContainer = function <T>({
  highlightColor,
  ...boxProps
}: { highlightColor?: string } & ComponentProps<typeof Box>) {
  return ({ getValue, ...props }: ContainerProps<T, true>) => (
    <Box
      fontFamily="Anthro"
      fontSize="12px"
      color={getValue().length ? highlightColor ?? 'purple.primary' : 'grey.text_dark'}
      maxW="100%"
      {...boxProps}
    >
      <components.SelectContainer getValue={getValue} {...props} />
    </Box>
  );
};

const MultiValueComponent = function <T>(props: MultiValueProps<T, true>) {
  return (
    <Text padding="0" lineHeight="18px">
      <components.MultiValue {...props} />
    </Text>
  );
};

const DropdownIndicator = function <T>(props: DropdownIndicatorProps<T>) {
  return (
    <components.DropdownIndicator {...props}>
      <ExpandMoreIcon />
    </components.DropdownIndicator>
  );
};

const Placeholder = function <T>({ className, ...props }: PlaceholderProps<T>) {
  return <components.Placeholder {...props} className={[className, 'placeholder'].filter(Boolean).join(' ')} />;
};

function isMultiOption<T>(value: MultiValue<T> | SingleValue<T>): value is MultiValue<T> {
  return Array.isArray(value);
}

type TextSelectProps<T, IsMulti extends boolean> = (IsMulti extends true
  ? {
      values: T[];
      value?: undefined;
      onChange: (values: T[]) => void;
    }
  : {
      values?: undefined;
      value: T | undefined;
      onChange: (value: T) => void;
    }) & {
  prettifyValue?: (value: T) => string;
  textToValue?: (text: string) => T;
  options: T[];
  creatable?: boolean;
  placeholder: string;
  icon?: ReactNode;
  isMulti: IsMulti;
  placeholderShownWithInput?: boolean;
  isLoading?: boolean;
  externalInput?: string;
  setExternalInput?: React.Dispatch<React.SetStateAction<string>>;
  valueIsNew?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: React.RefObject<any>;
  validate?: (value: string) => boolean;
  styleOverrides?: Record<string, CSSObjectWithLabel | ((_: unknown) => CSSObjectWithLabel)>;
  optionIsBold?: (value: T) => boolean;
  activeColor?: string;
  boxProps?: ComponentProps<typeof Box>;
  controlWrapperProps?:
    | ComponentProps<typeof HStack>
    | ((props: ControlProps<{ label: string; value: T }>) => ComponentProps<typeof HStack>);
  isClearable?: boolean;
  isSearchable?: boolean;
  hideSelectedOptions?: boolean;
  openMenuOnFocus?: boolean;
  isDisabled?: boolean;
};

function TextSelect<T, IsMulti extends boolean>({
  values,
  value,
  options,
  onChange,
  prettifyValue,
  textToValue,
  creatable,
  placeholder,
  icon,
  isMulti,
  placeholderShownWithInput = true,
  isLoading = false,
  externalInput,
  setExternalInput,
  valueIsNew,
  forwardedRef,
  validate,
  styleOverrides,
  optionIsBold,
  activeColor = Color.PURPLE_PRIMARY,
  boxProps,
  controlWrapperProps,
  openMenuOnFocus = true,
  isClearable = false,
  isSearchable = true,
  hideSelectedOptions,
  isDisabled,
}: TextSelectProps<T, IsMulti>) {
  type Option = { label: string; value: T };

  const valuesInternal = useMemo(
    () => (values ?? []).map((value) => ({ label: prettifyValue?.(value) ?? (value as string), value })) ?? [],
    [values, prettifyValue],
  );

  const transformedOptions = useMemo(
    () => options.map((option) => ({ label: prettifyValue?.(option) ?? (option as string), value: option })),
    [options],
  );

  const isValidNewOption = useCallback(
    (value: string) => {
      const alreadyAnOption = transformedOptions.some((option) => option.label === value);

      if (validate) {
        return validate(value) && !alreadyAnOption;
      }

      // default validate behaviour
      return !!value && !alreadyAnOption;
    },
    [transformedOptions, validate],
  );

  const [input, setInput] = useProxyState(externalInput, setExternalInput, '');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const Select = useMemo(() => (creatable ? CreatableSelect : ReactSelect), [creatable]);
  const selectRef = useProxyRef(forwardedRef);

  const Control: ComponentType<ControlProps<Option, boolean, GroupBase<Option>>> = useMemo(
    () => control(icon, controlWrapperProps),
    [icon, controlWrapperProps],
  );

  const selectContainer = useMemo(
    () => SelectContainer<Option>({ highlightColor: activeColor, ...boxProps }),
    [activeColor],
  );

  const onChangeInternal = useCallback(
    (selected: MultiValue<Option> | SingleValue<Option>) => {
      if (isMultiOption<Option>(selected)) {
        (onChange as (value: T[]) => void)(selected.map((item) => item.value));

        // if we can select another option, refocus the input
        setTimeout(() => {
          selectRef.current?.focus();
        }, 0);
      } else {
        (onChange as (value: T | undefined) => void)(selected?.value);
      }
    },
    [onChange],
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      if (isMulti) {
        e.preventDefault();
        e.stopPropagation();

        const text = e.clipboardData.getData('text');

        const newOptions = text
          .replace(/"/g, '')
          .split(/\s*,\s*/g)
          .map((item: string) => {
            const trimmedItem = item.trim();

            return isValidNewOption(trimmedItem)
              ? { label: trimmedItem, value: textToValue?.(trimmedItem) ?? (trimmedItem as T) }
              : null;
          })
          .filter((item): item is Option => !!item);

        onChangeInternal(valuesInternal.concat(newOptions));
      }
    },
    [isMulti, isValidNewOption, textToValue, onChangeInternal, valuesInternal],
  );

  const onPasteRef = useRef(onPaste);

  useEffect(() => {
    onPasteRef.current = onPaste;
  }, [onPaste]);

  const InputReplacement = useCallback(
    (props: InputProps<Option, IsMulti>) => <components.Input {...props} onPaste={onPasteRef.current} />,
    [onPasteRef],
  );

  useEffect(() => {
    const processedInput = input.trim().toLowerCase();

    setFilteredOptions(
      processedInput.length
        ? transformedOptions.filter((option) => option.label.toLowerCase().includes(processedInput))
        : transformedOptions,
    );
  }, [input, options]);

  return (
    <Select
      ref={selectRef}
      isDisabled={isDisabled}
      styles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          boxShadow: 'none',
          minHeight: '0',
          flexGrow: '1',
          ':last-of-type': {
            paddingRight: '6px',
          },
          ':first-of-type': {
            paddingLeft: '6px',
          },
          '::before':
            placeholderShownWithInput && (value || values?.length || input.length)
              ? {
                  content: `"${placeholder}:"`,
                  marginRight: '4px',
                  marginLeft: '2px',
                }
              : undefined,
          ':focus-within::before': placeholderShownWithInput
            ? {
                content: `"${placeholder}:"`,
                marginRight: '4px',
                marginLeft: '2px',
                color: activeColor,
              }
            : {},
          ...(styleOverrides?.control ?? {}),
        }),
        multiValue: (provided, props) => ({
          ...provided,
          backgroundColor: 'transparent',
          margin: '0',
          paddingInline: '0',
          display: 'inline-flex',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          flexWrap: 'nowrap' as any,
          ...(typeof styleOverrides?.multiValue === 'function'
            ? styleOverrides.multiValue(props)
            : styleOverrides?.multiValue ?? {}),
        }),
        singleValue: (provided) => ({
          ...provided,
          color: activeColor,
          overflow: 'visible',
          '::after': {
            display: valueIsNew ? 'inline' : 'none',
            content: '"new"',
            marginLeft: '8px',
            padding: '2px 8px',
            backgroundColor: activeColor,
            color: 'white',
            borderRadius: '5px',
            textAlign: 'center',
          },
          ...(styleOverrides?.singleValue ?? {}),
        }),
        valueContainer: (provided) => ({
          ...provided,
          gap: '4px',
          padding: '0',
          '> p:not(:last-of-type)::after': {
            content: '","',
          },
          ':focus-within': {
            minWidth: 'unset',
            '> .placeholder': placeholderShownWithInput
              ? {
                  display: 'none',
                }
              : undefined,
            '> p:last-of-type::after': {
              content: '","',
            },
          },
          ...(styleOverrides?.valueContainer ?? {}),
        }),
        multiValueLabel: (provided, props) => ({
          ...provided,
          fontFamily: 'Anthro',
          fontSize: '12px',
          padding: '0',
          paddingLeft: '0',
          color: 'inherit',
          ...(typeof styleOverrides?.multiValueLabel === 'function'
            ? styleOverrides.multiValueLabel(props)
            : styleOverrides?.multiValueLabel ?? {}),
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          display: 'none',
          ...(styleOverrides?.multiValueRemove ?? {}),
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '3px 0',
          ...(styleOverrides?.input ?? {}),
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '5px 0px 5px 4px',
          color: value || values?.length ? activeColor : Color.GREY_TEXT_DARK,
          ...(styleOverrides?.dropdownIndicator ?? {}),
        }),
        menu: (provided) => ({
          ...provided,
          width: 'fit-content',
          color: Color.GREY_TEXT_DARK,
          ...(styleOverrides?.menu ?? {}),
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
          fontSize: '12px',
          ...(styleOverrides?.menuPortal ?? {}),
        }),
        placeholder: (provided) => ({
          ...provided,
          color: Color.GREY_TEXT_LIGHT,
          ...(styleOverrides?.placeholder ?? {}),
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          marginLeft: 'auto',
          ...(styleOverrides?.indicatorsContainer ?? {}),
        }),
        option: (provided, props) => ({
          ...provided,
          fontWeight: optionIsBold?.(props.data.value) ? 'bold' : 'normal',
          ...(typeof styleOverrides?.option === 'function'
            ? styleOverrides.option(props)
            : styleOverrides?.option ?? {}),
        }),
        clearIndicator: (provided) => ({
          ...provided,
          padding: '0',
          paddingLeft: '4px',
          color: value || values?.length ? activeColor : Color.GREY_TEXT_DARK,
          ...(styleOverrides?.clearIndicator ?? {}),
        }),
      }}
      menuPortalTarget={document.body}
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable={isClearable}
      closeMenuOnSelect={!isMulti}
      blurInputOnSelect={!isMulti}
      openMenuOnFocus={openMenuOnFocus}
      menuPosition="fixed"
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        Control,
        SelectContainer: selectContainer,
        MultiValue: MultiValueComponent,
        Placeholder,
        Input: InputReplacement,
        Option,
        ClearIndicator,
      }}
      isLoading={isLoading}
      options={filteredOptions}
      onChange={onChangeInternal}
      onInputChange={(input, action) => {
        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
          setInput(input);
        }
      }}
      isValidNewOption={isValidNewOption}
      onCreateOption={(input) => {
        setInput('');

        if (isMulti) {
          onChangeInternal(valuesInternal.concat({ label: input, value: textToValue?.(input) ?? (input as T) }));
        } else {
          onChangeInternal({ label: input, value: textToValue?.(input) ?? (input as T) });
        }
      }}
      inputValue={input}
      value={
        isMulti
          ? valuesInternal
          : value
            ? { label: prettifyValue?.(value as T) ?? (value as string), value: value as T }
            : null
      }
      isSearchable={isSearchable}
      hideSelectedOptions={hideSelectedOptions}
    />
  );
}

function useProxyState<T>(
  proxy: T | undefined,
  proxyDispatch: React.Dispatch<React.SetStateAction<T>> | undefined,
  initialState: T,
) {
  const state = useState<T>(initialState);

  return proxyDispatch ? ([proxy, proxyDispatch] as [T, React.Dispatch<React.SetStateAction<T>>]) : state;
}

function useProxyRef<T>(proxy: React.RefObject<T> | undefined) {
  const ref = useRef<T | null>(null);

  return proxy ?? ref;
}

export { TextSelect };
