import { getUserStore, upsertUserStore } from '@services/localStorage';

export interface LastViewedPortfolio {
  teamId: string;
  portfolioId: string;
}

export const getLastViewedPortfolio = ({ email, teamId }: { email: string; teamId: string }): string | undefined => {
  const portfolios = getUserStore(email).lastViewedPortfolios;

  return portfolios.find(({ teamId: storedTeamId }) => storedTeamId === teamId)?.portfolioId;
};

export const upsertLastViewedPortfolio = ({
  email,
  teamId,
  portfolioId,
}: {
  email: string;
  teamId: string;
  portfolioId: string;
}): void => {
  const user = getUserStore(email);
  const index = user.lastViewedPortfolios.map(({ teamId }) => teamId).indexOf(teamId);

  if (index === -1) {
    user.lastViewedPortfolios.push({ teamId, portfolioId });
  } else {
    user.lastViewedPortfolios[index] = { teamId, portfolioId };
  }

  upsertUserStore(user);
};

export const removeLastViewedPortfolio = ({ email, teamId }: { email: string; teamId: string }): void => {
  const user = getUserStore(email);
  const index = user.lastViewedPortfolios.map(({ teamId }) => teamId).indexOf(teamId);

  if (index !== -1) {
    user.lastViewedPortfolios.splice(index, 1);
    upsertUserStore(user);
  }
};
