import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  ButtonProps,
} from '@chakra-ui/react';
import { z } from 'zod';
import { useFormik } from 'formik';
import confetti from 'canvas-confetti';
import { toast } from 'react-toastify';
import { useState } from 'react';

import Spacer from '@components/util/Spacer';
import { useCreateInviteProposalMutation } from '@generated/graphql';
import { toFormikValidate } from '@utils/zodFormikAdapter';
import { Icon } from '@utils/icons';
import { ensurehttps, striphttps } from '@utils/striphttps';
import { NemoLoadingCentered } from '@components/Loading';

const schema = z.object({
  name: z.string().min(1),
  fund: z.string().optional(),
  comment: z.string().optional(),
  linkedin: z
    .string()
    .regex(new RegExp(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([\w-]+)/, 'i'))
    .optional(),
  originalProfileId: z.string().optional(),
});

type InviteProposalSchema = z.infer<typeof schema>;

// eslint-disable-next-line @typescript-eslint/naming-convention
function ProposeNewUser(props: ButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      formik.resetForm();
    },
  });
  const [createInviteProposal] = useCreateInviteProposalMutation();
  const [loading, setLoading] = useState(false);
  const formik = useFormik<InviteProposalSchema>({
    initialValues: {
      name: '',
      fund: '',
      comment: '',
      linkedin: undefined,
      originalProfileId: undefined,
    },
    validate: async (values) => {
      const validateFn = toFormikValidate(schema);
      const zodValidationResult = await validateFn(values);

      const validationErrors = {
        ...zodValidationResult,
      };

      if (Object.keys(validationErrors).length === 0) {
        return undefined;
      }

      return validationErrors;
    },
    validateOnMount: true,
    validateOnChange: true,
    isInitialValid(props) {
      return schema.safeParse((props as { values: InviteProposalSchema }).values).success;
    },
    onSubmit: async (values, helpers) => {
      const input = schema.parse(values);
      try {
        const response = await createInviteProposal({
          variables: {
            input: {
              name: input.name.trim(),
              fund: input.fund ? input.fund.trim() : undefined,
              comment: input.comment ? input.comment.trim() : undefined,
              linkedin: input.linkedin ? ensurehttps(input.linkedin) : undefined,
              originalProfileId: input.originalProfileId,
            },
          },
        });
        if (!response.errors) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, 700);
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.8 },
          });
          toast(
            () => {
              return (
                <VStack align="flex-start" fontFamily="Anthro, sans-serif">
                  <Text fontWeight="600">Invite received!</Text>
                  <Text fontSize="12px">
                    When {input.name.split(' ')[0]} has been approved, we'll send you their invite link
                  </Text>
                </VStack>
              );
            },
            { type: 'success', autoClose: 9000 },
          );
        }
      } catch (err) {
        if (err instanceof Error && err.message === 'User with this email already exists') {
          toast('User with this email already exists', { type: 'error' });
        } else {
          toast('Failed to submit invite', { type: 'error' });
        }
      }
      helpers.resetForm();
    },
  });

  return (
    <>
      {/* */}
      {/* BUTTON  */}
      <Button
        id="connections-invite-button"
        onClick={() => {
          onOpen();
        }}
        h="unset"
        variant="ghost"
        color="grey.text_dark"
        flexShrink="0"
        px="8px"
        gap="12px"
        pl="20px"
        w="100%"
        py="6px"
        justifyContent="flex-start"
        _hover={{
          bg: 'rgba(135, 120, 247, 0.05)',
          color: 'purple.primary',
        }}
        {...props}
      >
        <Icon name="gifts" size="20px" />
        Invite
      </Button>

      {/* */}
      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
        <ModalOverlay />
        <ModalContent
          p="8px"
          maxW={{ base: '100vw', sm: '800px' }}
          w="auto"
          marginBottom={{ base: '0px', sm: '80px' }}
          maxH="100dvh"
          overflow="auto"
          alignSelf={{ base: 'flex-end', sm: 'flex-start' }}
          borderRadius="20px"
        >
          <ModalHeader pb="4px">
            <HStack justify="space-between" w="100%">
              <Text fontSize="18px" fontWeight="600">
                Who would you like to share deals with?
              </Text>
              <Button onClick={onClose} w="24px">
                <Icon name="close" size="16px" />
              </Button>
            </HStack>
          </ModalHeader>
          <ModalBody>
            {loading ? (
              <Box w="100%" my="50px">
                <NemoLoadingCentered />
              </Box>
            ) : (
              <HStack gap={4} align="flex-start" h="100%" minH={{ base: 'unset', sm: '380px' }}>
                <VStack w={410} alignSelf="stretch">
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                  >
                    <Text as="label" htmlFor="name" variant="accent" display="block" mb="2px">
                      Name *
                    </Text>
                    <Input
                      required
                      name="name"
                      placeholder="Angel Lee"
                      value={formik.values.name ?? ''}
                      onChange={(e) => {
                        const newValue = e.target.value === '' ? undefined : e.target.value;

                        formik.setFieldValue('name', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      borderColor={formik.touched.name && formik.errors.name ? 'red.300' : 'grey.border'}
                      data-1p-ignore
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Text fontSize="xs" color="red">
                        Please enter a name
                      </Text>
                    ) : null}

                    <Spacer size={20} />

                    <Text as="label" htmlFor="linkedin" variant="accent" display="block" mb="2px">
                      LinkedIn
                    </Text>
                    <InputGroup w="100%">
                      <InputLeftAddon border="1px solid" borderColor="grey.border" borderRight="none">
                        <Text fontSize="14px" color="GrayText" lineHeight="1.4">
                          https
                        </Text>
                      </InputLeftAddon>
                      <Input
                        type="text"
                        name="linkedin"
                        value={formik.values.linkedin ? striphttps(formik.values.linkedin) : ''}
                        onChange={(e) => {
                          const newValue = e.target.value === '' ? undefined : striphttps(e.target.value);
                          formik.setFieldValue('linkedin', newValue);
                        }}
                        onBlur={formik.handleBlur}
                        borderColor={
                          formik.touched.linkedin && formik.errors.linkedin && formik.values.linkedin?.length
                            ? 'red.300'
                            : undefined
                        }
                        variant="bold"
                        flexGrow={1}
                        w="unset"
                        placeholder="www.linkedin.com/in/angel-lee/"
                      />
                    </InputGroup>
                    {formik.touched.linkedin && formik.errors.linkedin && formik.values.linkedin?.length ? (
                      <Text fontSize="xs" color="red">
                        Please enter a valid LinkedIn profile URL
                      </Text>
                    ) : null}

                    <Spacer size={20} />

                    <Text as="label" htmlFor="email" variant="accent" display="block" mb="2px">
                      Why?
                    </Text>
                    <Textarea
                      name="comment"
                      placeholder="Let us know why they're a great fit for Azava."
                      rows={2}
                      maxLength={1000}
                      value={formik.values.comment ?? ''}
                      onChange={(e) => {
                        const newValue = e.target.value === '' ? undefined : e.target.value;

                        formik.setFieldValue('comment', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      borderColor={formik.touched.comment && formik.errors.comment ? 'red.300' : 'grey.border'}
                    />
                    {formik.touched.comment && formik.errors.comment ? (
                      <Text fontSize="xs" color="red">
                        Please enter a comment
                      </Text>
                    ) : null}
                    <Spacer size={8} />
                    <Text fontSize="12px" color="grey.text_dark" mb="auto" textAlign="left">
                      When this person joins Azava, you'll automatically start sharing. You can revoke their access
                      whenever.
                    </Text>

                    <HStack justify="space-between" mt="auto" py="16px">
                      <Button variant="ghost" onClick={onClose}>
                        Close
                      </Button>
                      <Button
                        colorScheme="purple"
                        isDisabled={!formik.isValid}
                        type="submit"
                        isLoading={formik.isSubmitting}
                      >
                        Request Invite
                      </Button>
                    </HStack>
                  </form>
                </VStack>
              </HStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { ProposeNewUser };
