import { createContext, useContext, useMemo, useState } from 'react';

import { EquityRoundType } from '#trpc';

type Company = {
  id: string;
  dealId: string;
  name: string;
  roundType: EquityRoundType | null;
};
const ActionBarContext = createContext<{
  selection: Company[];
  setSelection: (selection: Company[]) => void;
  selectedSet: Set<string>;
  selecting: boolean;
  setSelecting: (selecting: boolean) => void;
} | null>(null);

export function ActionBarProvider({ children }: { children: React.ReactNode }) {
  const [selection, setSelection] = useState<Company[]>([]);
  const [selecting, setSelecting] = useState<boolean>(false);
  const selectedSet = useMemo(() => new Set(selection.map((s) => s.id)), [selection]);

  return (
    <ActionBarContext.Provider value={{ selection, setSelection, selectedSet, selecting, setSelecting }}>
      {children}
    </ActionBarContext.Provider>
  );
}

export function useActionBar() {
  const context = useContext(ActionBarContext);
  if (!context) {
    throw new Error('useActionBar must be used within a ActionBarProvider');
  }
  return context;
}
