import avatarPlaceholder from '@/assets/icons/unicorn/unicorn_happy.svg';
import { getHostname } from '@utils/url_manipulations';
import { Avatar } from '@components/Avatar';

interface Props {
  alt?: string;
  url?: string | null;
  size: number;
  fetchSize?: number;
  isCircle?: boolean;
  borderRadius?: number;
  border?: boolean;
  useUnicornFallback?: boolean;
  backgroundColor?: string;
}

const CompanyLogo = ({
  url,
  alt,
  size,
  fetchSize,
  borderRadius,
  isCircle,
  border,
  useUnicornFallback = true,
  backgroundColor,
}: Props) => {
  const logoUrl = url
    ? new URL(
        `/api/public/icon?size=${fetchSize ?? size}&hostname=${getHostname(url)}`,
        import.meta.env.VITE_API_BASE_URL ?? 'http://localhost:3000',
      ).toString()
    : useUnicornFallback
      ? avatarPlaceholder
      : undefined;

  return (
    <Avatar
      src={logoUrl}
      initials={alt?.slice(0, 2) ?? ''}
      alt={alt ?? 'company logo'}
      size={size}
      borderRadius={borderRadius}
      isCircle={isCircle}
      border={border}
      backgroundColor={backgroundColor}
    />
  );
};

export { CompanyLogo };
