import {
  Flex,
  useDisclosure,
  VStack,
  Button,
  Spacer as ChakraSpacer,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Box,
} from '@chakra-ui/react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import DockToRightFilled from '@/assets/icons/dockToRightFilled.svg?react';
import { useFeatureFlagsContext } from '@services/feature_flag_provider';
import { UserContext } from '@services/user_provider';
import { Icon } from '@utils/icons';
import { Responsive } from '@components/Responsive';
import { SearchModal } from '@pages/Companies/SearchModal';

import { DevModeToggle } from './DevModeToggle';
import { NavElements } from './Navigation';
import { AzavaHomeLink } from './Home';
import { UserMenu } from './UserMenu';
import { LoginSection } from './LoginSection';

const MOBILE_BREAKPOINT = '768px';

const UserMenuSection = ({ showName }: { showName?: boolean }) => {
  const userContext = useContext(UserContext);

  return <>{userContext?.id ? <UserMenu showName={showName} /> : <LoginSection />}</>;
};

const navContext = createContext({
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  mobileOpen: false,
  mobileOnOpen: () => {},
  mobileOnClose: () => {},
  shouldExpand: false,
  setHovered: (value: boolean) => {},
});

const NavContextProvider = ({ children }: { children: React.ReactNode }) => {
  const isCoolingDown = useRef(false);
  const desktopDisclosure = useDisclosure({ defaultIsOpen: true });
  const mobileDisclosure = useDisclosure();
  const [isHovered, setIsHovered] = useState(false);

  const shouldExpand = desktopDisclosure.isOpen || isHovered;

  const handleToggle = () => {
    desktopDisclosure.onToggle();
    mobileDisclosure.onToggle();
    setIsHovered(false);
    isCoolingDown.current = true;
    setTimeout(() => {
      isCoolingDown.current = false;
    }, 200);
  };

  const setHovered = (value: boolean) => {
    if (!value) {
      isCoolingDown.current = false;
      setIsHovered(false);
      return;
    }

    if (desktopDisclosure.isOpen) return;
    if (isCoolingDown.current) return;

    setIsHovered(value);

    isCoolingDown.current = true;
    setTimeout(() => {
      isCoolingDown.current = false;
    }, 200);
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'b') {
        e.preventDefault();
        handleToggle();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [desktopDisclosure]);

  return (
    <navContext.Provider
      value={{
        isOpen: desktopDisclosure.isOpen,
        onOpen: desktopDisclosure.onOpen,
        onClose: desktopDisclosure.onClose,
        onToggle: handleToggle,
        mobileOpen: mobileDisclosure.isOpen,
        mobileOnOpen: mobileDisclosure.onOpen,
        mobileOnClose: mobileDisclosure.onClose,
        shouldExpand,
        setHovered,
      }}
    >
      {children}
    </navContext.Provider>
  );
};

const useNavContext = () => useContext(navContext);

function MenuDrawer({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen} isFullHeight>
      <DrawerOverlay />
      <DrawerContent margin="0" borderRadius="0" maxWidth="240px" maxH="100%">
        <DrawerHeader borderBottomWidth="1px" px="16px" py="0">
          <SidebarHeader />
        </DrawerHeader>
        <DrawerBody overflow="auto" px="16px">
          <SidebarInternal />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function AzavaSidebar() {
  const { mobileOpen, mobileOnClose } = useNavContext();

  const userContext = useContext(UserContext);
  const { id: userId } = userContext ?? {};
  return (
    <Responsive
      breakpoint={MOBILE_BREAKPOINT}
      above={<SidebarContent />}
      below={userId ? <MenuDrawer isOpen={mobileOpen} onClose={mobileOnClose} /> : null}
    />
  );
}

const SidebarContent = () => {
  const { isOpen, shouldExpand, setHovered } = useNavContext();

  return (
    <Flex position="relative">
      {!isOpen && (
        <Box
          position="absolute"
          left={0}
          top={0}
          width="10px"
          height="100vh"
          onMouseEnter={() => setHovered(true)}
          zIndex={1}
        />
      )}
      <AnimatePresence mode="sync" initial={false}>
        {shouldExpand ? (
          <motion.div
            key="sidebar"
            initial={{ width: '0' }}
            animate={{ width: '240px' }}
            exit={{ width: '0' }}
            transition={{ duration: 0.2, type: 'linear' }}
            style={{ overflow: 'hidden', flexShrink: '0', display: 'flex', flexDirection: 'row-reverse' }}
            onMouseLeave={() => setHovered(false)}
          >
            <Flex
              direction="column"
              height="100%"
              w={{ base: 'full', md: '240px' }}
              overflow="auto"
              borderRight={{ base: 'none', md: '1px solid' }}
              borderRightColor={{ base: 'none', md: 'grey.border' }}
              right="0"
              gap="0"
              px="16px"
              py="0"
              onMouseEnter={() => setHovered(true)}
            >
              <SidebarHeader />
              <SidebarInternal />
            </Flex>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Flex>
  );
};

const SidebarHeader = () => {
  const { isEnabled } = useFeatureFlagsContext();
  return (
    <Flex alignItems="center" justifyContent="space-between" gap={2} height="52px">
      <AzavaHomeLink />
      {isEnabled('DEV_MODE') ? <DevModeToggle /> : null}
      <SidebarButton />
    </Flex>
  );
};

const SidebarInternal = () => {
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" pb="16px">
        <SearchModal />
      </Flex>

      <VStack
        gap="0"
        fontSize="14px"
        color="grey.text_medium"
        fontWeight="500"
        align="stretch"
        justify="space-between"
        mb="20px"
        mx="-6px"
        w="calc(100% + 12px)"
        flexGrow="1"
      >
        <NavElements showIcons />
        <ChakraSpacer />
      </VStack>
      <UserMenuSection showName={true} />
    </>
  );
};

const SidebarButton = () => {
  const { onToggle, isOpen, mobileOpen } = useNavContext();

  return (
    <motion.div
      initial={{ opacity: 0, width: 0, marginRight: '-12px' }}
      exit={{ opacity: 0, width: 0, marginRight: '-12px' }}
      animate={{ opacity: 1, width: 'auto', marginRight: '0' }}
      transition={{ duration: 0.2 }}
      style={{ overflow: 'hidden' }}
    >
      <Button variant="ghost" color="grey.text_dark" w="auto" px="8px" onClick={onToggle}>
        <Responsive
          breakpoint={MOBILE_BREAKPOINT}
          above={
            isOpen ? (
              <DockToRightFilled style={{ width: '20px', height: '20px' }} />
            ) : (
              <Icon name="dockToRight" size="20px" />
            )
          }
          below={
            mobileOpen ? (
              <DockToRightFilled style={{ width: '20px', height: '20px' }} />
            ) : (
              <Icon name="dockToRight" size="20px" />
            )
          }
        />
      </Button>
    </motion.div>
  );
};

export { AzavaSidebar, NavContextProvider, useNavContext, SidebarButton };
