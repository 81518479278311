import { HStack, Text, Switch } from '@chakra-ui/react';
import { useContext } from 'react';

import { DevModeContext } from '@services/dev_mode_provider';

function DevModeToggle() {
  const { devMode, toggleDevMode } = useContext(DevModeContext);

  return (
    <HStack flexShrink={0}>
      <Text color="green">Dev</Text>
      <Switch isChecked={devMode} onChange={() => toggleDevMode()} colorScheme="green" />
    </HStack>
  );
}

export { DevModeToggle };
