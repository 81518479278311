import { ReactNode, useState } from 'react';
import { Box, Button, HStack, Spacer, UseToastOptions } from '@chakra-ui/react';

import { Icon } from '@utils/icons';

function ExpandingToast({
  isClosable,
  onClose,
  icon,
  children,
}: Parameters<Exclude<UseToastOptions['render'], undefined>>[0] & { icon: ReactNode; children: ReactNode }) {
  const [forceExpanded, setForceExpanded] = useState(false);

  return (
    <Box
      w="fit-content"
      p="16px"
      bg="black"
      color="white"
      borderRadius="12px"
      onClick={isClosable ? onClose : () => setForceExpanded(!forceExpanded)}
      position="relative"
    >
      <HStack gap="0">
        {icon}
        <Box
          display="flex"
          w={{ base: forceExpanded ? '236px' : '0px', sm: '236px' }}
          opacity={{ base: forceExpanded ? 1 : 0, sm: 1 }}
          transition="width 0.3s ease-in-out 0s, opacity 0.3s linear 0s"
          overflow="hidden"
          position="relative"
        >
          <Box w="8px" h="20px" flexShrink="0" />
          <Box>{children}</Box>
          <Spacer w="24px" h="20px" flexShrink="0" />
          <Spacer w="8px" h="100%" pos="absolute" bg="linear-gradient(to left, black, transparent)" top="0" right="0" />
        </Box>
      </HStack>
      <Button
        variant="text"
        position="absolute"
        right="-1px"
        top="-1px"
        h="18px"
        w="18px"
        bg="white"
        borderRadius="0 12px 0 8px"
        color="black"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        minWidth="0px"
      >
        <Icon name="close" />
      </Button>
      <Box h="12px" w="12px" top="17px" right="0px" bg="white" pos="absolute" borderRadius="0 0 0 12px">
        <Box h="12px" w="12px" bg="black" borderRadius="0 11px 0 0" />
      </Box>
      <Box h="12px" w="12px" top="0px" right="17px" bg="white" pos="absolute" borderRadius="0 0 0 12px">
        <Box h="12px" w="12px" bg="black" borderRadius="0 11px 0 0" />
      </Box>
    </Box>
  );
}

export { ExpandingToast };
