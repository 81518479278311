import { PopoverTrigger, Popover, PopoverContent, Portal, Link, LinkProps } from '@chakra-ui/react';
import { Link as RRLink } from 'react-router-dom';

import { ProfileCompactCard } from '@components/ProfileCompactCard';

export default function ProfileNameWithPreview({
  fullname,
  slug,
  linkProps,
  disablePopover,
}: {
  fullname: string;
  slug?: string | null;
  linkProps?: LinkProps;
  disablePopover?: boolean;
}) {
  return (
    <Popover placement="bottom-start" trigger="hover">
      <PopoverTrigger>
        <Link
          as={RRLink}
          to={`/${slug}`}
          display="inline-block"
          textDecoration="underline"
          textDecorationColor="#77777700"
          textUnderlineOffset="4px"
          transition="text-decoration-color 0.2s"
          _hover={{ textDecorationColor: '#777' }}
          p="0"
          pr="4px"
          position="relative"
          {...linkProps}
        >
          {fullname}
        </Link>
      </PopoverTrigger>
      {slug && !disablePopover ? (
        <Portal>
          <PopoverContent w="400px" p="0px" borderColor="grey.border">
            <ProfileCompactCard slug={slug} />
          </PopoverContent>
        </Portal>
      ) : null}
    </Popover>
  );
}
