import { useEffect, useState } from 'react';
import { Box, keyframes } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

const wiggle = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '25%': { transform: 'rotate(10deg)' },
  '75%': { transform: 'rotate(-10deg)' },
  '100%': { transform: 'rotate(0deg)' },
});

export function CountBox({ count }: { count?: string | number | null }) {
  const [prevCount, setPrevCount] = useState<string | number | null | undefined>(null);
  const [currCount, setCurrCount] = useState(count);

  useEffect(() => {
    if (count !== currCount) {
      setCurrCount(count);
      setPrevCount(currCount);
    }
  }, [count, currCount]);

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {count ? (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{}}
        >
          <Box
            key={count}
            h="16px"
            px="6px"
            minW="32px"
            background="purple.veryLight"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
            display="flex"
            fontSize="10px"
            flexShrink="0"
            color="black"
            animation={prevCount === null ? undefined : `0.2s linear 0s ${wiggle}`}
          >
            {count}
          </Box>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
