import { Flex, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from '@components/Avatar';
import { useAuthentication } from '@services/authentication_provider';
import { UserContext } from '@services/user_provider';
import { Color } from '@styled/themes/v2';
import { Icon } from '@utils/icons';
import { FeatureFlagsContext } from '@services/feature_flag_provider';
import InsightsStars from '@/assets/icons/insights-stars.svg?react';

import { toTitleCase } from '#shared/utils/toTitleCase';

const UserMenu = ({ showName }: { showName?: boolean }) => {
  const userContext = useContext(UserContext);
  const { isEnabled } = useContext(FeatureFlagsContext);
  const { initials, publicProfile, email, isPlatformAdmin } = userContext ?? {};
  const { logout } = useAuthentication();
  const handleLogout = () => logout({ logoutParams: { returnTo: window.location.origin } });

  return (
    <Menu>
      {showName ? (
        <HStack>
          <MenuButton
            bgColor="transparent"
            _hover={{
              bgColor: 'purple.veryLight',
            }}
            w="calc(100% + 12px)"
            px="12px"
            py="8px"
            mx="-6px"
            mb="8px"
            borderRadius="full"
            transition="background-color 0.3s"
          >
            <HStack
              spacing="8px"
              align="center"
              w="200px"
              _hover={{
                bgColor: 'red',
              }}
            >
              <Avatar
                src={publicProfile?.imageUrl ?? undefined}
                initials={initials ?? ''}
                size={24}
                backgroundColor={Color.PURPLE_PRIMARY}
                isCircle
              />
              <Text color="grey.text_dark" fontWeight="500">
                {publicProfile?.fullname ? toTitleCase(publicProfile?.fullname.split(' ')[0]) : ''}
              </Text>
            </HStack>
          </MenuButton>
        </HStack>
      ) : (
        <MenuButton
          p="4px"
          bgColor="transparent"
          _hover={{
            bgColor: 'gray.100',
          }}
          borderRadius="full"
          transition="background-color 0.3s"
        >
          <Avatar
            src={publicProfile?.imageUrl ?? undefined}
            initials={initials ?? ''}
            size={24}
            backgroundColor={Color.PURPLE_PRIMARY}
            isCircle
          />
        </MenuButton>
      )}

      <MenuList
        zIndex={1000}
        borderRadius="7px"
        border="1px solid"
        borderColor="grey.divider"
        background="white"
        py="10px"
        fontSize="14px"
        gap={1}
      >
        <HStack marginBottom="15px" px="15px">
          <Avatar
            src={publicProfile?.imageUrl ?? undefined}
            initials={initials ?? ''}
            size={30}
            backgroundColor={Color.PURPLE_PRIMARY}
            isCircle
          />
          <VStack gap="1" alignItems="start" minW="120px">
            <Text color="black" fontWeight="500">
              {publicProfile?.fullname ? toTitleCase(publicProfile?.fullname) : ''}
            </Text>
            {email && (
              <Text color={Color.GREY_TEXT_DARK} fontSize="12px">
                {email}
              </Text>
            )}
          </VStack>
        </HStack>
        <MenuItem as={Link} to="/profile/me" fontWeight="500" p="4px 15px" borderRadius="2px">
          <Flex gap="8px" align="center">
            <Icon name="person" color="gray.500" size="16px" />
            My Profile
          </Flex>
        </MenuItem>
        <MenuItem as={Link} to="/settings" fontWeight="500" p="4px 15px" borderRadius="2px">
          <Flex gap="8px" align="center">
            <Icon name="settings" color="gray.500" size="16px" />
            Account Settings
          </Flex>
        </MenuItem>

        <MenuDivider m="4px 15px" />

        {isPlatformAdmin ? (
          <MenuItem as={Link} to="/admin" fontWeight="400" p="4px 15px" borderRadius="2px">
            <Flex gap="8px" align="center">
              <Icon name="admin" color="red" size="16px" />
              Admin Panel
            </Flex>
          </MenuItem>
        ) : null}
        {isEnabled('DEALFLOW_INSIGHTS') ? (
          <MenuItem as={Link} to="/dealflow-insights" fontWeight="400" p="4px 15px" borderRadius="2px">
            <Flex gap="8px" align="center">
              <InsightsStars width="16px" />
              Dealflow Insights
            </Flex>
          </MenuItem>
        ) : null}
        <MenuItem as={Link} to="/terms" fontWeight="500" p="4px 15px" borderRadius="2px">
          <Flex gap="8px" align="center">
            <Icon name="gavel" color="gray.500" size="16px" />
            Terms
          </Flex>
        </MenuItem>
        <MenuItem as={Link} to="/privacy" fontWeight="500" p="4px 15px" borderRadius="2px">
          <Flex gap="8px" align="center">
            <Icon name="lock" color="gray.500" size="16px" />
            Privacy
          </Flex>
        </MenuItem>

        <MenuDivider m="4px 15px" />

        {userContext?.teams && userContext.teams.length > 1 ? (
          <>
            <Text ml="16px" py="4px" display="inline-flex" gap="6px">
              <Icon name="compareArrows" color="gray.500" size="16px" />
              Switch Team
            </Text>
            {userContext.teams.map((team) => (
              <MenuItem
                key={team.id}
                onClick={() => {
                  userContext.setSelectedTeam(team);
                  window.location.reload();
                }}
                fontWeight="400"
                p="4px 15px"
                borderRadius="2px"
              >
                <Flex
                  gap="8px"
                  align="center"
                  color={
                    team.id === (userContext.selectedTeam ?? userContext.defaultTeam)?.id
                      ? 'purple.primary'
                      : 'gray.500'
                  }
                >
                  <Icon name="group" size="16px" />
                  {team.name}
                </Flex>
              </MenuItem>
            ))}
            <MenuDivider m="4px 15px" />
          </>
        ) : null}

        <MenuItem onClick={handleLogout} fontWeight="500" color={Color.RED} p="4px 15px" borderRadius="2px">
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export { UserMenu };
