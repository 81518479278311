import { createContext, useContext, useMemo, useState } from 'react';

import { RouterOutputs } from '@services/trpc';

type Deal = RouterOutputs['views']['pipeline']['getDeals']['deals'][number];
const ActionBarContext = createContext<{
  selection: Deal[];
  setSelection: React.Dispatch<React.SetStateAction<Deal[]>>;
  selectedSet: Set<string>;
  selecting: boolean;
} | null>(null);

export function ActionBarProvider({ children }: { children: React.ReactNode }) {
  const [selection, setSelection] = useState<Deal[]>([]);
  const selectedSet = useMemo(() => new Set(selection.map((s) => s.id)), [selection]);
  const selecting = useMemo(() => selection.length > 0, [selection]);

  return (
    <ActionBarContext.Provider value={{ selection, setSelection, selectedSet, selecting }}>
      {children}
    </ActionBarContext.Provider>
  );
}

export function useActionBar() {
  const context = useContext(ActionBarContext);
  if (!context) {
    throw new Error('useActionBar must be used within a ActionBarProvider');
  }
  return context;
}
