import { DropdownTippyProps } from '@components/Dropdowns/DropdownTippy';
import { DealStatus } from '@generated/graphql';
import convertUnderscore from '@utils/convertUnderscore';
import { TextSelect } from '@components/v2/Select';
import { Color } from '@styled/themes/v2';

export const dealStatusOptions = {
  [DealStatus.NO_STATUS]: { value: DealStatus.NO_STATUS, colour: '#616161' },
  [DealStatus.CONSIDERING]: { value: DealStatus.CONSIDERING, colour: '#D8601D' },
  [DealStatus.COMMITTED]: { value: DealStatus.COMMITTED, colour: '#089741' },
  [DealStatus.INVESTED]: { value: DealStatus.INVESTED, colour: '#04a3d8' },
  [DealStatus.PASSED]: { value: DealStatus.PASSED, colour: '#D81D1D' },
  [DealStatus.LOST]: { value: DealStatus.LOST, colour: '#A76F6F' },
  [DealStatus.WATCHING]: { value: DealStatus.WATCHING, colour: '#7f74ce' },
  [DealStatus.HELPING_FUNDRAISE]: { value: DealStatus.HELPING_FUNDRAISE, colour: '#BD00E3' },
} as const;

export const newOptions = Object.values(dealStatusOptions);
type DealStatusOptions = (typeof newOptions)[number];

interface Props {
  selected: DealStatus;
  onChange: (newStatus: DealStatus) => void;
  dropdownTippyProps?: Omit<DropdownTippyProps, 'children'>;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
  collapsible?: boolean;
  hideBorder?: boolean;
  background?: string;
}

function prettifyValue(value: (typeof newOptions)[number]) {
  return value.value === DealStatus.NO_STATUS
    ? '+ Status'
    : value.value === DealStatus.HELPING_FUNDRAISE
      ? 'Supporting'
      : convertUnderscore(value.value);
}

function NewStatusDropdown({ selected, onChange, hideBorder, background }: Props) {
  return (
    <TextSelect<DealStatusOptions, false>
      placeholder="No assignee"
      options={newOptions.filter((o) => o.value !== DealStatus.NO_STATUS)}
      value={dealStatusOptions[selected]}
      onChange={({ value }) => onChange(value)}
      isMulti={false}
      placeholderShownWithInput={false}
      isLoading={false}
      isSearchable={false}
      prettifyValue={prettifyValue}
      styleOverrides={{
        menu: {
          zIndex: 2,
          width: '120px',
        },
        selectContainer: {
          color: 'grey.primary',
        },
        input: {
          maxWidth: '100%',
          padding: '0',
        },
        control: {
          padding: '2px 6px !important',
          cursor: 'pointer',
          border: hideBorder ? 'none' : '1px solid',
          borderColor: selected === DealStatus.NO_STATUS ? 'transparent !important' : undefined,
          borderRadius: background ? '0px' : '4px',
          backgroundColor: background ?? 'white',
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: 'DM Mono',
          ':hover': {
            borderColor:
              selected === DealStatus.NO_STATUS ? 'rgb(246, 246, 247) !important' : dealStatusOptions[selected].colour,
            backgroundColor: selected === DealStatus.NO_STATUS ? 'rgb(246, 246, 247)' : 'rgb(246, 246, 247)',
          },
        },
        dropdownIndicator: {
          display: 'none',
        },
        option: (props) => {
          // TODO: get rid of this any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const pps = props as any;

          return {
            fontFamily: 'DM Mono',
            color: pps.data.value.colour,
            backgroundColor: pps.isSelected ? Color.GREY_DIVIDER : pps.isFocused ? Color.GREY_BACKGROUND : 'white',
          };
        },
      }}
      activeColor={dealStatusOptions[selected].colour}
      controlWrapperProps={{
        fontSize: '12px',
        lineHeight: '1.2',
        p: 0,
        _focusWithin: { outline: 'none' },
        border: 'none',
        borderRadius: '0',
        fontFamily: 'DM Mono',
        mb: '1px',
      }}
    />
  );
}

export { NewStatusDropdown };
