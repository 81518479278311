import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';

import {
  NotificationsCountSubscriptionDocument,
  NotificationsCountSubscriptionSubscriptionResult,
  NotificationsCountSubscriptionSubscriptionVariables,
  useNotificationsCountQuery,
} from '@generated/graphql';
import { useUserContext } from '@services/user_provider';
import { Icon } from '@utils/icons';

import { OurNavLink } from '../NavLink';

import { CountBox } from './CountBox';

const ActivityNavLink = ({ showIcons }: { showIcons?: boolean }) => {
  const {
    userSettings: { notificationsLastSeenAt },
  } = useUserContext();

  const {
    data: notificationsData,
    refetch,
    subscribeToMore,
  } = useNotificationsCountQuery({
    variables: { since: notificationsLastSeenAt },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      NotificationsCountSubscriptionSubscriptionResult,
      NotificationsCountSubscriptionSubscriptionVariables
    >({
      document: NotificationsCountSubscriptionDocument,
      updateQuery: (prev) => {
        refetch();

        return prev;
      },
    });

    return unsubscribe;
  }, []);

  const notificationsCount = notificationsData?.notificationsCount ?? 0;

  return (
    <Box position="relative" w="100%">
      <OurNavLink
        to="/activity"
        title="Activity"
        py="6px"
        icon={<Icon name="notifications" size="20px" />}
        showIcons={showIcons}
      >
        {notificationsCount ? <CountBox count={notificationsCount} /> : null}
      </OurNavLink>
    </Box>
  );
};

export { ActivityNavLink };
