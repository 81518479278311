import { Text, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

function AzavaHomeLink() {
  return (
    <Link as={NavLink} to="/">
      <Text fontSize="16px" fontWeight="bold" color="purple.primary">
        AZAVA
      </Text>
    </Link>
  );
}

export { AzavaHomeLink };
