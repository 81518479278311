import { Button, ButtonProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const RequestAccessButton = ({ message = 'Apply to join', ...props }: { message?: string } & ButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate('/apply-to-join');
      }}
      {...props}
    >
      {message}
    </Button>
  );
};

export { RequestAccessButton };
