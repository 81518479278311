import { useCallback, useState } from 'react';
import {
  Button,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Input,
  VStack,
  useToast,
  Divider,
} from '@chakra-ui/react';
import { GoogleLogin } from '@react-oauth/google';

import { RequestAccessButton } from '@pages/Home/RequestAccessButton';
import { useRestEndpoint } from '@hooks/useRestEndpoint';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg?react';
import { ExpandingToast } from '@pages/Deals/ExpandingToast';
import { Icon } from '@utils/icons';
import { NemoLoading } from '@components/Loading';
import { Responsive } from '@components/Responsive';

const LoginSection = ({ showRequestAction = 'show' }: { showRequestAction?: 'show' | 'hide' }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <HStack spacing="12px" flexGrow="0" ml="auto">
      <Menu placement="bottom-end" isOpen={menuOpen}>
        <MenuButton
          as={Button}
          variant="outline"
          colorScheme="purple"
          onClick={() => setMenuOpen(!menuOpen)}
          gap="0px"
          rightIcon={
            <ChevronDownIcon style={{ transform: `rotate(${menuOpen ? 0 : 180}deg)`, transition: 'transform 0.2s' }} />
          }
          pr="13px"
        >
          Sign in
        </MenuButton>
        <Portal>
          <MenuList
            border="1px solid"
            borderColor="grey.divider"
            borderRadius="4px"
            p="12px"
            gap="20px"
            display="flex"
            flexDir="column"
            zIndex={1000}
            bg="white"
            boxShadow="rgba(99, 99, 99, 0.3) 0px 2px 20px 0px"
            w="300px"
          >
            <LoginCard setMenuOpen={setMenuOpen} />
          </MenuList>
        </Portal>
      </Menu>
      {showRequestAction === 'show' ? (
        <Responsive
          breakpoint="sm"
          above={<RequestAccessButton colorScheme="purple" />}
          below={<RequestAccessButton colorScheme="purple" message="Join" />}
        />
      ) : null}
    </HStack>
  );
};
function LoginCard({
  setMenuOpen,
  redirectPath,
}: {
  setMenuOpen?: (open: boolean) => void;
  redirectPath?: string | null;
}) {
  const [loading, setLoading] = useState(false);
  const [magicLinkEmail, setMagicLinkEmail] = useState('');
  const [getSessionFromGoogleToken] = useRestEndpoint<{ token: string; email: string }>({
    route: '/api/public/auth/google/callback',
  });
  const [requestMagicLink] = useRestEndpoint<void>({
    route: '/api/public/auth/requestMagicLink',
  });
  const toast = useToast();

  const handleEnterMagicLink = useCallback(async () => {
    setLoading(true);
    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = redirectPath ?? currentUrl.pathname;
    await requestMagicLink({
      body: JSON.stringify({ email: magicLinkEmail, redirectUrl: currentUrl.toString() }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    toast({
      duration: 3000,
      isClosable: false,
      position: 'bottom-right',
      containerStyle: {
        maxW: 'unset',
        minW: 'unset',
      },
      render: (props) => (
        <ExpandingToast {...props} icon={<Icon name="mail" />}>
          <Text alignSelf="center">Magic Link Sent!</Text>
        </ExpandingToast>
      ),
    });

    setMagicLinkEmail('');
    setMenuOpen?.(false);
    setTimeout(() => setLoading(false), 100);
  }, [redirectPath, magicLinkEmail, setMenuOpen, requestMagicLink, toast]);
  return (
    <>
      {loading ? (
        <NemoLoading />
      ) : (
        <>
          <HStack justify="flex-end" w="100%" gap="0px">
            <GoogleLogin
              width={274}
              onSuccess={async (credentialResponse) => {
                if (!credentialResponse.credential) {
                  console.error('No credential found');
                  return;
                }

                const body = JSON.stringify({
                  idToken: credentialResponse.credential,
                });
                const res = await getSessionFromGoogleToken({
                  body,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                });

                if (res.data?.token) {
                  const url = new URL(window.location.href);
                  if (redirectPath) {
                    url.pathname = redirectPath;
                  }
                  url.searchParams.set('email', res.data.email);
                  url.searchParams.set('token', res.data.token);
                  window.location.href = url.toString();
                }
                // TODO: handle error case
              }}
              onError={() => {
                // TODO: handle error case
              }}
            />
          </HStack>
          <HStack>
            <Divider />
            <Text textAlign="center" fontWeight={500} color="grey.text_medium" fontSize="14px">
              OR
            </Text>
            <Divider />
          </HStack>
          <VStack align="flex-end">
            <Input
              value={magicLinkEmail}
              onClick={(e) => {
                e.stopPropagation();
              }}
              placeholder="Enter your email"
              autoCapitalize="off"
              onChange={(e) => setMagicLinkEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEnterMagicLink();
                }
              }}
              borderColor="grey.border"
            />
            <Button
              variant="outline"
              colorScheme="purple"
              disabled={!magicLinkEmail}
              w="100%"
              onClick={handleEnterMagicLink}
            >
              Send Magic Link
            </Button>
          </VStack>
        </>
      )}
    </>
  );
}

export { LoginSection, LoginCard };
